import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import withLightbox from '../Lightbox/settings';
import Icons from '../Icons';

import { MainColour } from '../../utils/variables';

import './mobile.css';

import loadComponents from '../Loadable';

const MenuItems = loadComponents('menu-items');
// const Hamburger = loadComponents('menu-hamburger');
const Portal = loadComponents('portal');
const Backdrop = loadComponents('lightbox-backdrop');
const LightboxButton = loadComponents('lightbox-button');
const CtaButton = loadComponents('cta-button');

export default withLightbox(function MobileMenu({
  showLightbox,
  openLightbox,
  closeLightbox,
}) {
  const menu = useStaticQuery(graphql`
    query mobileMenuQuery {
      wpMenu(databaseId: { eq: 2 }) {
        ...MenuData
      }
    }
  `);
  return (
    <div style={{ height: `36px`, width: `36px` }}>
      <button
        type="button"
        aria-label="Open Menu"
        className="hidden"
        onClick={e => openLightbox(e)}
        style={buttonStyle}
      >
        <Icons.Hamburger size="36px" />
      </button>
      {showLightbox && (
        <Portal portal="menu">
          <Backdrop closeModal={closeLightbox} noLoader />
          <nav
            style={{
              position: `fixed`,
              right: 0,
              top: 0,
              bottom: 0,
              overflowX: `hidden`,
              overflowY: `auto`,
              zIndex: 510,
              maxWidth: `100%`,
              animation: `mobileMenu 0.3s forwards`,
            }}
          >
            <div
              style={{
                background: MainColour,
                padding: `5.625rem 1.875rem 1.875rem`,
                height: `100vh`,
              }}
            >
              <LightboxButton onClick={closeLightbox} close>
                Close
              </LightboxButton>
              <div
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  height: `100%`,
                }}
              >
                <ul
                  itemScope
                  itemType="http://www.schema.org/SiteNavigationElement"
                  style={{
                    margin: 0,
                    opacity: 0,
                    animation: `mobileInner 0.3s forwards`,
                    animationDelay: `300ms`,
                  }}
                >
                  <MenuItems
                    menu={menu.wpMenu.menuItems.nodes}
                    closeLightbox={closeLightbox}
                    mobile
                  />
                </ul>
                <div
                  style={{
                    marginTop: `auto`,
                    alignSelf: `center`,
                    justifySelf: `flex-end`,
                  }}
                >
                  <CtaButton alt />
                </div>
              </div>
            </div>
          </nav>
        </Portal>
      )}
    </div>
  );
});

const buttonStyle = {
  display: `inline-block`,
  verticalAlign: `top`,
  lineHeight: 0,
  position: `absolute`,
  top: `50%`,
  right: `15px`,
  transform: `translate(0,-50%)`,
};
